import LoadableCollection from "@/entities/LoadableCollection";

export default class BatchCollection extends LoadableCollection {

    hasApproved() {
        return this.getApproved().length > 0;
    }

    getApproved() {
        return this.getItems().filter(item => item.isApproved());
    }

    getNew() {
        return this.getItems().filter(item => item.isNew());
    }

    hasNew() {
        return this.getNew().length > 0;
    }

    hasReConfirmed() {
        return this.getReConfirmed().length > 0;
    }

    getReConfirmed() {
        return this.getItems().filter(item => item.isReConfirmed());
    }

    getDeletable() {
        return this.getItems().filter(item => item.isDeletable());
    }

    hasDeletable() {
        return this.getDeletable().length > 0;
    }

    getBatchFromId(batchId)
    {
        let elements = this.getItems().filter(item => item.getId() === batchId);
        return elements.length === 1 ? elements[0] : null;
    }
}