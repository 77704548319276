<template>
  <Table @page="onPage" :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
        {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="elements.length">
      <TableElement @click="$emit('details', element)" v-for="element in elements" v-bind:key="element.getId()">
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ element.getId() }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Call @click.stop :telephone="element.getFarm().getTelephone()" />
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
            <FarmerName :farmer="element.getFarm()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Price :amount="element.getTotalPriceWithVat()" :currency="element.getCurrency()"/>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <DayTimeEstimation :moment="element.getCycle().getCollectBatch()" :hours="element.getCollectingHours()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Status class="text-wrap" :status="element.getStatus()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Button class="mr-auto ml-auto" :key="rerender" v-if="canUpdateBatch && !element.canShowStatusModal() && getSendButtonIconProps(element)" v-bind:disabled="!element.isProcessable()" @click.stop="sendBatch(element)" :type="getSendButtonIconProps(element).type" :name="getSendButtonIconProps(element).message"></Button>
            <Button class="mr-auto ml-auto" v-else-if="canUpdateBatch && element.canShowStatusModal()" @click.stop="showStatusModal(element)" type="eye" name="batches.buttons.show"></Button>
            <Button class="mr-auto ml-auto mt-2" v-if="canUpdateBatch && element.isDeletable()" @click.stop="deleteBatch(element)" type="delete" name="batches.buttons.delete"/>
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="!elements.length" >
      <TableElement :size="headers.length" :empty-message="$t('batches.list.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Button from "@/components/elements/Button";
import EmittedEvent from "@/entities/EmittedEvent";
import Status from "@/components/elements/Status";
import Price from "@/components/elements/Price";
import Call from "@/components/elements/Call";
import {AuthorizedActionsMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import FarmerName from "@/components/farmer/backoffice/FarmerName";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";

export default {
  name: "BatchTable",
  components: {DayTimeEstimation, FarmerName, Call, Price, Status, Button, TableElement, TableElementItem, Table},
  mixins: [ScreenSizeMixin, AuthorizedActionsMixin],
  emits: ['page', 'send', 'show', 'details'],
  props: {
    elements: Array,
    pagination: {
      type: Object
    }
  },
  data: function() {
    return {
      batch: null,
      allHeaders: [
          'batches.list.details.id',
          'batches.list.contact',
          'batches.list.name',
          'batches.list.total',
          'batches.list.delivery_estimation',
          'batches.list.status',
          'batches.list.action',
      ],
      responsiveHeaders: [
        'batches.list.contact',
        'batches.list.name',
        'batches.list.status',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    rerender: function () {
      return this.$store.state.rerender;
    },
    activeCycle: function () {
      return this.$store.state.cycle;
    },
  },
  methods: {
    onPage(pageNr) {
      this.$emit('page', pageNr);
    },
    showStatusModal(batch) {
      this.$emit('show', batch);
    },
    sendBatch(batch) {
      let type = this.extractTypeFromBatch(batch);
      if (type) {
        this.$emit('send', new EmittedEvent(type, batch));
      }
    },
    deleteBatch(batch) {
      this.$emit('send', new EmittedEvent('batches.list.confirm.delete', batch))
    },
    extractTypeFromBatch(batch) {
      let type = '';

      if (batch.isNew()) {
        type = 'batches.list.confirm.confirm';
      } else if (batch.isApproved()) {
        type = 'batches.list.confirm.send';
      } else if (batch.isConfirmed()) {
        type = 'batches.list.confirm.prepack'
      } else if (batch.isReConfirmed()) {
        type = 'batches.list.confirm.send_instructions';
      } else if (batch.isCollectionReady() || batch.isCollected()) {
        type = '';
      } else {
        console.log('Batch status is not recognized');
      }

      return type;
    },
    getSendButtonIconProps(batch)
    {
      switch (this.extractTypeFromBatch(batch)) {
        case "batches.list.confirm.confirm":
          return {type: 'confirm', message:'batches.buttons.confirm'};
        case "":
          return null;
        default:
          return {type: 'send', message:'batches.buttons.send'};
      }
    },
  }
}
</script>

<style scoped>

</style>
