<template>
  <Modal :with-footer="true" :name="$t(details.title, {farmer: batch.getFarm().getName()})" @closemodal="$emit('closemodal')" :dismissible="true">
    <template v-slot:default>

      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('batches.list.total')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11">
          <Price :amount="batch.getTotalPriceWithVat()" :currency="batch.getCurrency()" />
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('batches.list.delivery_estimation')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11">
          <DayTimeEstimation :moment="activeCycle && activeCycle.getCollectBatch()" :hours="batch.getCollectingHours()" />
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('batches.list.status')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11">
          <Status :status="batch.getStatus()"/>
        </dd>
      </dl>
      <br class="clearfix"/>
      <br class="clearfix"/>
      <p>{{ $t(details.body) }}</p>
      <div v-bind:class="!copied ? 'alert-info':'alert-success'" class="alert alert-styled-left border-top-0 border-bottom-0 border-right-0">
        <span v-if="isScreenLgWidth" class="font-weight-semibold text-wrap">{{link}}</span>
        <span v-else class="font-weight-semibold text-wrap">{{ $t(copyText) }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <Button v-clipboard:success="onCopy" v-clipboard:copy="link" type="copy" :name="$t('batches.buttons.copy')"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import Price from "@/components/elements/Price";
import Status from "@/components/elements/Status";
import {ScreenSizeMixin} from "@/mixins/GeneralMixin";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";

export default {
  name: "BatchStatusModal",
  mixins: [ScreenSizeMixin],
  components: {DayTimeEstimation, Status, Price, Modal, Button},
  emits: ['closemodal'],
  props: {
    batch: Object
  },
  data() {
    return {
      copied: false,
      copyText: 'general.url_copy',
    }
  },
  computed: {
    activeCycle: function () {
      return this.$store.state.cycle;
    },
    link: function () {
      let routeName = 'frontoffice.breadcrumb.farmers.batch';
      if (this.batch.isPrepacking()) {
        routeName = 'frontoffice.breadcrumb.farmers.prepack';
      }
      if (this.batch.isPacking()) {
        routeName = 'frontoffice.breadcrumb.farmers.pack';
      }

      let props = this.$router.resolve({
        name: routeName,
        params: { id: this.batch.getFarm().getId(), batchId: this.batch.getId() },
      });

      return window.location.origin + props.href;
    },
    details: function () {

      if (this.batch.isSentToFarm()) {
        return {body: 'batches.modal.status.in_review', title: 'batches.modal.title.in_review'};
      }

      if (this.batch.isPrepacking()) {
        return {body: 'batches.modal.status.in_prepacking', title: 'batches.modal.title.in_prepacking'};
      }

      if (this.batch.isPacking()) {
        return {body: 'batches.modal.status.in_confirmation', title: 'batches.modal.title.in_confirmation'};
      }

      return {body: "", title: ""};
    },
  },
  methods: {
    onCopy: function () {
      this.copied = true;
      this.copyText = "general.url_copied";
    },
  }
}
</script>

<style scoped>

</style>